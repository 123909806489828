import React from "react"
import Layout from "../components/layout"
import BlogPostPreview from "../components/BlogPostPreview/blogPostPreview"
import { Link,graphql } from "gatsby"
import { css } from "@emotion/core"

// TODO: make this a component 
export default ({ data }) => {
  return (
    
  <Layout>
    <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
    {data.allMarkdownRemark.edges.map(({ node }) => (
        <BlogPostPreview
        title={node.frontmatter.title}
        excerpt={node.excerpt}
        date={node.frontmatter.date}
        slug={node.fields.slug}
      />
        ))}
  </Layout>)
}

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: {
        fields: [frontmatter___date]
        order: DESC
      }
      filter: {
        frontmatter: {published: {ne: false}}
      }
    ) {
      
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            published
            date(formatString: "MMMM DD, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`