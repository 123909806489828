import React from "react"
import { Link } from "gatsby"
import "./blogPostPreview.css"
class BlogPostPreview extends React.Component {
    render() {
        const { title, excerpt,date,slug } = this.props;
      return (
        <article className="blogPostPreview">
        <Link
            to={slug}
            >
            <h3>
            {title}
            <span className="date"
            >
             — {date}
            </span>
            </h3>
            <hr/>
            <p>{excerpt}</p>
            </Link>
            </article>
      );
    }
  }

export default BlogPostPreview;